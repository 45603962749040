import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaLinkedin,
  FaSquareInstagram,
  FaLocationDot,
  FaPhone,
  FaRegEnvelope,
  FaSquareXTwitter,
} from "react-icons/fa6";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { FaFacebookSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-wrapper">
              <div className="wrapper">
                <div className="footer-logo">
                  <h1 className="text-gredient">
                    <span>Code</span>
                    <span>Sculpt</span>
                  </h1>
                </div>

                <p className="mt-5">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Tempore repellendus nulla ut.
                </p>
                <p>
                  <strong>Privacy Policy | Terms & Conditions</strong>
                </p>

                <div className="socal">
                  <p className="mb-0">
                    <strong>Follow Us</strong>
                  </p>
                  <NavLink
                   className="effect" 
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaLinkedin />
                  </NavLink>
                  <NavLink
                   className="effect" 
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaFacebookSquare />
                  </NavLink>
                  <NavLink
                   className="effect" 
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaSquareInstagram />
                  </NavLink>

                  <NavLink
                   className="effect" 
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaSquareXTwitter />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="footer-wrapper">
              <div className="wrapper">
                <h3>Services</h3>

                <ul className="ps-0 mt-4">
                  <li>
                    <NavLink className="effect" to="/"> Web Design</NavLink>
                  </li>
                  <li>
                    <NavLink className="effect" to="/"> Graphic Design</NavLink>
                  </li>
                  <li>
                    <NavLink className="effect" to="/"> Web Devlopment</NavLink>
                  </li>
                  <li>
                    <NavLink className="effect" to="/"> App Devlopment</NavLink>
                  </li>
                  <li>
                    <NavLink className="effect" to="/"> Digital Marketing</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-wrapper">
              <div className="wrapper">
                <h3>Contact Info</h3>

                <ul className="ps-0 mt-4">
                  <li className="d-flex ">
                    <span className="icon-box me-2">
                      <FaLocationDot />
                    </span>
                    <span>
                      <p className="">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Odio, cupiditate!
                      </p>
                    </span>
                  </li>

                  <li className="d-flex">
                    <span className="icon-box me-2">
                      <FaPhone />
                    </span>
                    <span>
                      <NavLink
                        className="effect effect-white"
                        to="tel:1234567890"
                      >
                        1234567890
                      </NavLink>
                    </span>
                  </li>

                  <li>
                    <span className="icon-box me-2">
                      <TbDeviceLandlinePhone />
                    </span>
                    <span>
                      <NavLink
                        className="effect effect-white"
                        to="tel:123456789"
                      >
                        123456789
                      </NavLink>
                    </span>
                  </li>
                  <li>
                    <span className="icon-box me-2">
                      <FaRegEnvelope />
                    </span>
                    <NavLink
                      className="effect effect-white"
                      to="mailto:codesculpt@gmail.com"
                      target="_blank"
                      rel="noopener"
                    >
                      codesculpt@gmail.com
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
