import React from 'react'
import { FaCircleCheck } from "react-icons/fa6";
import { NavLink} from "react-router-dom";
const Price = () => {
  return (
    <section className=" pricing-wrapper">
        <div className="container py-5">
          <div className="heading text-center text-white-shadow">
            <h2> Pick the perfect Plan.</h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="wrapper">
                <div className="text-wrapper text-white-shadow">
                  <span className="fs-4">Subscription</span>
                  <h3 className='text-gredient'>Standard</h3>
                  <p>
                    <strong>What’s included?</strong>
                  </p>

                  <ul className="p-0 m-0">
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>We’ll work on one design at a time</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>Delivery in 2-4 business days</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>No limit on brands or users</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>Free stock photos included</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>Easy online payment</span>
                    </li>
                  </ul>
                  <h4 className=" my-4 h2">$100.00/m</h4>

                  <NavLink className="pricing_card_button" to="/">
                    {" "}
                    Contact Us
                  </NavLink>
                  <NavLink
                    className="d-block text-center mt-3 text-white-shadow text-decoration-underline pb-2"
                    to="/"
                  >
                    {" "}
                    Call Us
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="wrapper">
                <div className="text-wrapper text-white-shadow">
                  <span className="fs-4">Subscription</span>
                  <h3 className='text-gredient'>Pro</h3>
                  <p>
                    <strong>What’s included?</strong>
                  </p>

                  <ul className="p-0 m-0">
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>We’ll work on one design at a time</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>Delivery in 2-4 business days</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>No limit on brands or users</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>Free stock photos included</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>Easy online payment</span>
                    </li>
                  </ul>
                  <h4 className=" my-4 h2">$100.00/m</h4>

                  <NavLink className="pricing_card_button" to="/">
                    {" "}
                    Contact Us
                  </NavLink>
                  <NavLink
                    className="d-block text-center mt-3 text-white-shadow text-decoration-underline pb-2"
                    to="/"
                  >
                    {" "}
                    Call Us
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="wrapper">
                <div className="text-wrapper text-white-shadow">
                  <span className="fs-4">Subscription</span>
                  <h3 className='text-gredient'>Project</h3>
                  <p>
                    <strong>What’s included?</strong>
                  </p>

                  <ul className="p-0 m-0">
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>We’ll work on one design at a time</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>Delivery in 2-4 business days</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>No limit on brands or users</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>Free stock photos included</span>
                    </li>
                    <li className="d-flex align-items-center">
                      <span className="me-2">
                        <FaCircleCheck />
                      </span>{" "}
                      <span>Easy online payment</span>
                    </li>
                  </ul>
                  <h4 className=" my-4 h2">$100.00/m</h4>

                  <NavLink className="pricing_card_button" to="/">
                    {" "}
                    Contact Us
                  </NavLink>
                  <NavLink
                    className="d-block text-center mt-3 text-white-shadow text-decoration-underline pb-2"
                    to="/"
                  >
                    {" "}
                    Call Us
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Price