import React from 'react'
import { IoSchoolSharp } from "react-icons/io5";
import { MdShoppingCart ,MdOutlineHealthAndSafety,MdRealEstateAgent } from "react-icons/md";
import { GiSelfLove } from "react-icons/gi";




const Industries = () => {
  return (
    <section>
        <div className="container industries-wrapper py-5">
          <div className="heading text-center text-white-shadow">
            <h2> Industries We Serve</h2>
          </div>
          <div className="row">
            <div className="col-md-3 mt-3">
              <div className="wrapper">
                <span>
                  <IoSchoolSharp />
                </span>
                <span>Education</span>
              </div>
            </div>
            <div className="col-md-3 mt-3">
            <div className="wrapper">
              <span>
                <MdShoppingCart />
              </span>
              <span>Education</span>
            </div>
          </div>
          <div className="col-md-3 mt-3">
          <div className="wrapper">
            <span>
              <MdOutlineHealthAndSafety />
            </span>
            <span>Education</span>
          </div>
        </div>
        <div className="col-md-3 mt-3">
        <div className="wrapper">
          <span>
            <GiSelfLove />
          </span>
          <span>Education</span>
        </div>
      </div>
      <div className="col-md-3 mt-3">
        <div className="wrapper">
          <span>
            <MdRealEstateAgent />
          </span>
          <span>Education</span>
        </div>
      </div>
      <div className="col-md-3 mt-3">
      <div className="wrapper">
        <span>
          <MdRealEstateAgent />
        </span>
        <span>Education</span>
      </div>
    </div>
          </div>
        </div>
      </section>
  )
}

export default Industries