import React from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";

const WhySlider = () => {
  const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    speed: 1000,
  };
  return (
    
      <Slider {...settings}>
        <div className="slide-wrapper">
          <div className="item ">
            <div className="row d-flex align-items-center">
              <div className="col-md-6 px-5">
                <div className="wrapper">
                  <div className="text-wrapper">
                    <h3>Imagination</h3>
                    <p>
                    What sets us apart from other web design and development companies that you might be considering? Our team of experienced professionals is dedicated to delivering customized solutions that meet your unique needs. We take the time to understand your business goals, target audience, and design preferences to create a website that not only looks great but also performs well in terms of user experience and search engine optimization. We use the latest technologies and design trends to ensure that your website is modern, responsive, and user-friendly. We also provide ongoing support and maintenance to ensure that your website remains up-to-date and secure. With our focus on quality, transparency, and customer satisfaction, we are confident that we can exceed your expectations and help you achieve your online goals.
                    </p>
                    <NavLink className="button" to="/">
                    <span class="text">Contact Us</span>
                    </NavLink>
                   
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wrapper">
                  <div className="img-wrapper">
                    <img src="/images/why1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
     {
//        <div className="slide-wrapper">
//       <div className="item ">
//         <div className="row d-flex align-items-center">
//           <div className="col-md-6 px-5">
//             <div className="wrapper">
//               <div className="text-wrapper">
//                 <h3>Vision</h3>
//                 <p>
//                   We’re not interested in recreating the wheel — reworking
//                   tired old layouts, copying competitor sites or reusing
//                   commercial templates. We bring a fresh pair of eyes to
//                   every project, as well as the inspiration, curiosity and
//                   creative skills necessary to ensure your next project is
//                   one-of-a-kind. Otherwise, what’s the point?
//                 </p>

//                 <NavLink className="button" to="/">
//                     <span class="text">Contact Us</span>
//                     </NavLink>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="wrapper">
//               <div className="img-wrapper">
//                 <img src="/images/why2.png" alt="" />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="slide-wrapper">
//     <div className="item ">
//       <div className="row d-flex align-items-center">
//         <div className="col-md-6 px-5">
//           <div className="wrapper">
//             <div className="text-wrapper">
//               <h3>Efficiency</h3>
//               <p>
//                 We’re not interested in recreating the wheel — reworking
//                 tired old layouts, copying competitor sites or reusing
//                 commercial templates. We bring a fresh pair of eyes to
//                 every project, as well as the inspiration, curiosity and
//                 creative skills necessary to ensure your next project is
//                 one-of-a-kind. Otherwise, what’s the point?
//               </p>
//               <NavLink className="button" to="/">
//               <span class="text">Contact Us</span>
//               </NavLink>
//             </div>
//           </div>
//         </div>
//         <div className="col-md-6">
//           <div className="wrapper">
//             <div className="img-wrapper">
//               <img src="/images/why3.png" alt="" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//   <div className="slide-wrapper">
//   <div className="item ">
//     <div className="row d-flex align-items-center">
//       <div className="col-md-6 px-5">
//         <div className="wrapper">
//           <div className="text-wrapper">
//             <h3>Fun</h3>
//             <p>
//               We’re not interested in recreating the wheel — reworking
//               tired old layouts, copying competitor sites or reusing
//               commercial templates. We bring a fresh pair of eyes to
//               every project, as well as the inspiration, curiosity and
//               creative skills necessary to ensure your next project is
//               one-of-a-kind. Otherwise, what’s the point?
//             </p>
//             <NavLink className="button" to="/">
//                     <span class="text">Contact Us</span>
//                     </NavLink>
//           </div>
//         </div>
//       </div>
//       <div className="col-md-6">
//         <div className="wrapper">
//           <div className="img-wrapper">
//             <img src="/images/why4.png" alt="" />
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
}
      </Slider>
    
  );
};

export default WhySlider;
