import React from "react";

const PageBanner = ({ image, pageName, heading, heading2, para }) => {
  return (
    <section className="page-banner">
      <div className="container pt-4">
        <div className="row d-flex align-items-center">
          <div className="col-md-6">
            <div className="wrapper">
              <div className="text">
                <span className="page-name py-2 position-relative">{pageName}</span>
                <h1 className="py-4">{heading} <span className="text-gredient">{heading2}</span> </h1>
                <p>{para}</p>
              </div>
            </div>
          </div>
          
          <div className="col-md-6">
            <div className="wrapper ps-5 position-relative">
              <div className="background-blur"></div>
              <div className="img">
                <img src={image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageBanner;
