import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageBanner from "../components/PageBanner";
import Testimonials from "../components/Testimonials";
import {
  FaLinkedin,
  FaSquareInstagram,
  FaPhone,
  FaRegEnvelope,
  FaSquareXTwitter,
} from "react-icons/fa6";


import { FaFacebookSquare } from "react-icons/fa";
import FAQ from "../components/FAQ";
import Industries from "../components/Industries";
const MobileApp = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const banner = {
    image: "/images/mobile.png",
    pageName: "Mobile App Devlopment",
    heading: "Reach Out for  ",
    heading2: "Excellence",
    para: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, doloremque at provident cumque deleniti ipsum dolorum porro vel ab enim!",
  };

  const faq = {
    data: [
      {
        id: 1,
        heading: "How much does it cost to build a mobile app?",
        para: "The cost of building a mobile app can vary widely depending on factors such as complexity, features, platform (iOS, Android, or both), and the development team's location. Simple apps may cost a few thousand dollars, while more complex ones can range into the hundreds of thousands or more.",
      },
      {
        id: 2,
        heading: "Who creates mobile apps?",
        para: "Mobile apps are typically created by mobile app developers or development teams. These professionals are skilled in programming languages like Java or Kotlin for Android apps and Swift or Objective-C for iOS apps.",
      },
      {
        id: 3,
        heading: "Are mobile apps profitable for your business?",
        para: "Yes, Mobile apps can be profitable for businesses in various ways, including direct sales, in-app purchases, subscriptions, or through increased customer engagement and brand loyalty. The profitability depends on the app's purpose, target audience, and monetization strategy.",
      },
      {
        id: 4,
        heading: "How long does it take to create a mobile app?",
        para: "The time required to create a mobile app varies based on complexity and features. Simple apps may take a few weeks, while more complex ones can take several months. Factors such as design, development, testing, and iteration can influence the timeline. It's essential to plan carefully and communicate effectively with the development team to ensure a successful and timely app launch.",
      },
    ],
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>Our Works</title>
        <meta name="description" content="Welcome " />
      </Helmet>
      <PageBanner {...banner} />
      <section className="bg-black">
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <h2 className="text-white section-heading">
                Mobile App Development{" "}
                <span className="text-gredient">Company</span>{" "}
              </h2>

              <p className="text-white-shadow">
                Are you searching for the best mobile app development company to
                take your business to the next level? Look no further than Code
                Sculpt - a world-renowned leader in mobile application
                development. With a team of highly skilled professionals, we
                offer top-notch services to help elevate your business presence
                and reach your target audience effectively.
              </p>
              <p className="text-white-shadow">
                We use the latest technology and ideas to create a mobile app
                that is unique and perfect for your business. When you work with
                us, you will see how we combine creativity and technology to
                make the best app for your business.
              </p>

              <h3 className="text-white">
                Why Our Clients Value Our Mobile App Developers
              </h3>
              <p className="text-white-shadow">
                Our clients love our team of mobile app developers for their
                unwavering dedication, innovative solutions, and exceptional
                quality of the mobile apps they provide, backed by 10+ years of
                experience.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg2">
        <div className="container py-5 why-wrapper">
          <div className="heading text-center text-gredient">
            <h2> What our clients says !</h2>
          </div>
          <Testimonials />
        </div>
      </section>
      <section className="bg-black">
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <h2 className="section-heading text-white-shadow">
                Unlock Innovation with Our Top-Quality Services for{" "}
                <span className="text-gredient">Mobile App Development</span>
              </h2>

              <p className="text-white-shadow">
                Take your business to new heights with our exceptional mobile
                app development services. We're a specialized mobile app
                development company based in India. Our experienced app
                developers work tirelessly to transform your innovative app
                concepts into a reality. We guarantee that your mobile app
                development will stand out in the digital world.
              </p>
              <ul className="dot-list text-white ps-3">
                <li>
                  <p className="text-white-shadow">
                    <strong> Mobile App Development</strong> <br />
                    Our expertise in mobile app development across various
                    platforms ensures startups and enterprises seamless,
                    responsive experiences for their users, bringing their
                    vision to life from conceptualization to deployment.
                  </p>
                </li>

                <li>
                  <p className="text-white-shadow">
                    <strong> Android App Development</strong> <br />
                    Our Android app development services are tailored to meet
                    industry standards and offer top-notch performance,
                    security, and scalability. With millions of users, we take
                    great pride in delivering an exceptional user experience.
                  </p>
                </li>
                <li>
                  <p className="text-white-shadow">
                    <strong> iOS App Development</strong> <br />
                    Precision, innovation, and aesthetic appeal are at the
                    forefront of our iOS app development services. Our goal is
                    to unlock the full potential of iOS devices by creating
                    sleek, intuitive, high-performance applications that
                    captivate users.
                  </p>
                </li>
                <li>
                  <p className="text-white-shadow">
                    <strong> Hybrid App Development</strong> <br />
                    We take great pride in our hybrid app development services.
                    We have a team of highly skilled developers who use
                    cutting-edge technologies and innovative techniques to
                    create apps that are both functional and user-friendly.
                  </p>
                </li>
                <li>
                  <p className="text-white-shadow">
                    <strong> React Native App Development</strong> <br />
                    When it comes to building user-friendly applications for
                    both iOS and Android users, React Native app development is
                    the perfect choice. Our team of React Native experts ensures
                    that your app is efficient and cost-effective.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5 contact-note">
          <div className="row">
            <div className="col">
              <div className="wrapper text-center p-4">
                <h2 className=" text-white text-center">
                  Get in touch with our mobile app developers today to discuss
                  unique ideas for your app development.
                </h2>

                <NavLink className="button" to="/">
                  <span>Contact Us</span>{" "}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-black">
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <h2 className="section-heading text-white-shadow">
                Code Sculpt is more than just a mobile app development company{" "}
                <span className="text-gredient">in the world</span>
              </h2>

              <p className="text-white-shadow">
                At our mobile app development company, we deeply understand the
                constantly evolving landscape of mobile app development. We
                strive to stay ahead by embracing the latest technologies and
                trends to thrive in this dynamic ecosystem. Our dedicated team
                of mobile application developers is passionate about providing
                you with the best possible solutions for your mobile app
                development needs. We are committed to being your trusted
                partner for all your mobile app development requirements.
              </p>

              <h3 className="text-white">Progressive Web Apps (PWAs)</h3>
              <p className="text-white-shadow">
                Progressive Web Apps, commonly known as PWAs, offer the best of
                both worlds by combining the benefits of web and mobile
                applications. These apps are designed to function smoothly
                across multiple platforms and devices. PWAs are created using
                standard web technologies such as HTML, CSS, and JavaScript and
                are readily available through a web browser. Notably, PWAs
                feature a range of exciting capabilities such as offline
                functionality, push notifications, and much more.
              </p>
              <h3 className="text-white">Internet of Things (IoT)</h3>
              <p className="text-white-shadow">
                IOT is a network of physical objects, also called "things," that
                are equipped with sensors, software, and connectivity. The
                connection between these objects allows them to collect and
                exchange data over the internet. This technology acts as a
                bridge between humans and the digital world, which results in
                automated processes, efficient data analysis, and improved
                overall productivity. The data collected by IoT devices is used
                to make informed decisions, streamline processes, and create new
                applications and services.
              </p>

              <h3 className="text-white">Blockchain</h3>
              <p className="text-white-shadow">
                Blockchain technology provides a secure and transparent way of
                recording transactions, which goes beyond the use case of
                cryptocurrencies. Transactions are grouped into blocks and
                connected through cryptographic methods, creating an
                unchangeable chain. To ensure data security and to make it
                extremely difficult for malicious actors to make changes, each
                block contains a cryptographic hash of the previous one.
              </p>
              <h3 className="text-white">Cloud-based Technology</h3>
              <p className="text-white-shadow">
                Cloud-based technology offers many benefits, including
                scalability, flexibility, cost-efficiency, and simplified
                maintenance. It frees businesses from the need for extensive
                hardware investments and complex IT infrastructure management,
                allowing them to focus on their core operations. Users can
                access cloud services from various devices with an internet
                connection, promoting collaboration and remote access to data
                and applications.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <h2 className="section-heading ">
                Our Mobile Application{" "}
                <span className="text-gredient">Development Process</span>
              </h2>

              <p>
                Our mobile application development company in India is dedicated
                to providing you with a custom solution that meets your business
                requirements. We understand that each business has unique needs,
                and therefore, we use a well-structured procedure to create a
                tailored application that is optimized to address your specific
                needs. Our team of experienced professionals works closely with
                you to ensure that your application is designed and developed to
                meet your business objectives and provide a seamless user
                experience. We strive to deliver the best possible outcomes, and
                we do this by employing the latest technologies and industry
                best practices in our development process.
              </p>
              <h3 className="h4 mt-3">1. Initial Analysis:</h3>
              <ul className="dot-list  ps-3">
                <li>
                  <p>
                    This is the foundation stage where you gather information
                    and define your app. Identify the target audience, problem
                    you're solving, unique value proposition, and
                    functionalities needed.
                  </p>
                </li>

                <li>
                  <p>
                    Conduct market research to understand competitors and user
                    needs.
                  </p>
                </li>
                <li>
                  <p>
                    Create user personas to define your ideal users and their
                    pain points.
                  </p>
                </li>
                <li>
                  <p>Outline the app's features and technical requirements.</p>
                </li>
              </ul>

              <h3 className="h4 mt-3">2. Designing:</h3>
              <ul className="dot-list  ps-3">
                <li>
                  <p>
                    This stage focuses on designing the user interface (UI) and
                    user experience (UX).
                  </p>
                </li>

                <li>
                  <p>
                    Create wireframes and mockups to visualize the app's layout
                    and flow.
                  </p>
                </li>
                <li>
                  <p>
                    Design screens and interactions that are intuitive,
                    user-friendly, and aesthetically pleasing.
                  </p>
                </li>
                <li>
                  <p>Ensure accessibility for users with disabilities.</p>
                </li>
              </ul>

              <h3 className="h4 mt-3">3. Development:</h3>
              <ul className="dot-list  ps-3">
                <li>
                  <p>
                    This is where the app comes to life! Developers will code
                    the app based on the design specifications.
                  </p>
                </li>

                <li>
                  <p>
                    Choose the appropriate programming languages and frameworks
                    for your target platform (iOS, Android, etc.).
                  </p>
                </li>
                <li>
                  <p>Integrate APIs and functionalities as needed.</p>
                </li>
                <li>
                  <p>
                    {" "}
                    Utilize code version control and best practices for secure
                    and maintainable development.
                  </p>
                </li>
              </ul>
              <h3 className="h4 mt-3">4. Testing:</h3>
              <ul className="dot-list  ps-3">
                <li>
                  <p>
                    Thoroughly test the app to ensure it functions as intended
                    and is free of bugs.
                  </p>
                </li>

                <li>
                  <p>
                    Perform unit testing, integration testing, and user
                    acceptance testing.
                  </p>
                </li>
                <li>
                  <p>
                    Test on various devices and operating systems to guarantee
                    compatibility.
                  </p>
                </li>
                <li>
                  <p>Fix any bugs or issues discovered during testing.</p>
                </li>
              </ul>
              <h3 className="h4 mt-3">5. Deployment:</h3>
              <ul className="dot-list  ps-3">
                <li>
                  <p>
                    This stage involves publishing the app to app stores like
                    Google Play and Apple App Store.
                  </p>
                </li>

                <li>
                  <p>
                    Prepare documentation and marketing materials for launch.
                  </p>
                </li>
                <li>
                  <p>
                    Consider phased rollout or beta testing for controlled
                    release and feedback.
                  </p>
                </li>
              </ul>
              <h3 className="h4 mt-3">6. Post-Deployment Maintenance:</h3>
              <ul className="dot-list  ps-3">
                <li>
                  <p>
                    This ongoing stage ensures the app's smooth operation and
                    user satisfaction.
                  </p>
                </li>

                <li>
                  <p>
                    Monitor app performance and analytics to identify areas for
                    improvement.
                  </p>
                </li>
                <li>
                  <p>Address user feedback and bug reports promptly.</p>
                </li>
                <li>
                  <p>
                    Provide regular updates with new features and bug fixes.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-black">
        <div className="container py-5">
          <div className="row p-3">
            <div className="col-md-5">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/profile.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6">
              <div className="wrapper text-white-shadow">
                <h2 className="section-heading text-gredient">About Me</h2>
                <h3>
                  Hello, I'm{" "}
                  <span className="text-gredient">Ranvijay Yadav</span>{" "}
                </h3>
                <h5>MERN stack developer</h5>
                <p>
                  Experienced MERN stack developer dedicated to creating robust
                  and innovative app solutions. Proficient in MongoDB, Express,
                  React, and Node.js, with a commitment to delivering
                  high-quality, user-centric applications.
                </p>

                <span>
                  <strong>More Details</strong>
                </span>

                <div className="socal profile-social">
                  <NavLink
                    className="effect"
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaLinkedin />
                  </NavLink>
                  <NavLink
                    className="effect"
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaFacebookSquare />
                  </NavLink>
                  <NavLink
                    className="effect"
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaSquareInstagram />
                  </NavLink>

                  <NavLink
                    className="effect"
                    to="/"
                    target="_blank"
                    rel="noopener"
                  >
                    <FaSquareXTwitter />
                  </NavLink>
                </div>

                <ul className="ps-0 mt-4">
                  <li className="d-flex mb-3">
                    <span className="icon-box me-2">
                      <FaPhone />
                    </span>
                    <span>
                      <NavLink
                        className="effect effect-white"
                        to="tel:1234567890"
                      >
                        1234567890
                      </NavLink>
                    </span>
                  </li>

                  <li>
                    <span className="icon-box me-2">
                      <FaRegEnvelope />
                    </span>
                    <NavLink
                      className="effect effect-white"
                      to="mailto:codesculpt@gmail.com"
                      target="_blank"
                      rel="noopener"
                    >
                      codesculpt@gmail.com
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Industries />

      <section className="faq-wrapper bg-black">
        <div className="container py-5">
          <div className="heading text-center text-white-shadow">
            <h2> Frequently asked questions</h2>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="wrapper">
                <FAQ FaqData={faq} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MobileApp;
