import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageBanner from "../components/PageBanner";
import "./other.css";
import { FaRegStar } from "react-icons/fa6";
import Brands from "../components/Brands";
import FAQ from "../components/FAQ";
import Testimonials from "../components/Testimonials";
import Price from "../components/Price";

const Service = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const banner = {
    image: "/images/service-banner.png",
    pageName: "Our Services",
    heading: "Everything you need to know about ",
    heading2: "Code Sculpt",
    para: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, doloremque at provident cumque deleniti ipsum dolorum porro vel ab enim!",
  };
  const faq ={
    data:[
      {
        id:1,
        heading:"What types of web and app development services do you offer?",
        para:"Our company specializes in creating custom web and mobile applications tailored to your business needs. We handle everything from the initial design and user experience to the final deployment. Our web development services include responsive website design, e-commerce solutions, and CMS development. For app development, we focus on both iOS and Android platforms, ensuring a seamless user experience across all devices."
      },
      {
        id:2,
        heading:"How does your digital marketing service help businesses grow?",
        para:"Our digital marketing services are designed to increase your online presence and engage your target audience effectively. We offer search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, content marketing, and email marketing campaigns. Our team works closely with you to develop a customized strategy that aligns with your business goals, targeting the right audience to enhance your brand's reach and conversion rates."
      },
      {
        id:3,
        heading:"Can you develop e-commerce platforms for online businesses?",
        para:"Absolutely! We specialize in developing robust e-commerce platforms that offer a seamless shopping experience. Our solutions include custom shopping cart development, payment gateway integration, product catalog management, and secure transaction processing. We ensure that your e-commerce platform is user-friendly, mobile-responsive, and optimized for conversions."
      },
      {
        id:4,
        heading:"What is your process for developing a custom app or website?",
        para:"Our development process begins with understanding your business requirements and goals. We then move on to the planning and design phase, where we create wireframes and prototypes. After your approval, our development team starts building the app or website, followed by rigorous testing for quality assurance. Finally, we deploy the product and provide ongoing support and maintenance."
      },
      {
        id:5,
        heading:"How do you ensure the quality and security of the websites and apps you develop?",
        para:"Quality and security are our top priorities. We adhere to the latest coding standards and best practices in web and app development. Our team conducts thorough testing, including functionality, usability, and security tests, to ensure that your app or website performs flawlessly and is secure against cyber threats. We also provide regular updates and maintenance to keep your digital assets running smoothly and securely."
      },
  
  
  ]
  }

  return (
    <div className="page-content">
      <Helmet>
        <title>Our Services</title>
        <meta name="description" content="Welcome " />
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
      <PageBanner {...banner} />
      
   
      <section className="bg-black home-service-wrapper">
        <div className="container py-5 ">
          <div className="heading text-center text-white-shadow">
            <h2>Our Main Services</h2>
          </div>
          <div className="row">
           
            <div className="col-md-6 mt-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/service1.jpg" alt="" />
                </div>
                <div className="text-wrapper">
                  <span className="count">01</span>
                  <h3>Web Desigining</h3>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Repudiandae quia natus, mollitia exercitationem recusandae
                    unde consequuntur temporibus obcaecati accusantium ipsum. Ab
                    aliquam quo, expedita quibusdam accusantium a velit
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4">
            <div className="wrapper">
              <div className="img-wrapper">
                <img src="/images/web-devlopment.jpg" alt="" />
              </div>
              <div className="text-wrapper">
                <span className="count">02</span>
                <h3>Web Devlopment</h3>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Repudiandae quia natus, mollitia exercitationem recusandae
                  unde consequuntur temporibus obcaecati accusantium ipsum. Ab
                  aliquam quo, expedita quibusdam accusantium a velit
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
          <div className="wrapper">
            <div className="img-wrapper">
              <img src="/images/service1.jpg" alt="" />
            </div>
            <div className="text-wrapper">
              <span className="count">03</span>
              <h3>App Devlopment</h3>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Repudiandae quia natus, mollitia exercitationem recusandae
                unde consequuntur temporibus obcaecati accusantium ipsum. Ab
                aliquam quo, expedita quibusdam accusantium a velit
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-4">
        <div className="wrapper">
          <div className="img-wrapper">
            <img src="/images/service2.jpg" alt="" />
          </div>
          <div className="text-wrapper">
            <span className="count">04</span>
            <h3>Digital Marketing</h3>
            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Repudiandae quia natus, mollitia exercitationem recusandae
              unde consequuntur temporibus obcaecati accusantium ipsum. Ab
              aliquam quo, expedita quibusdam accusantium a velit
            </p>
          </div>
        </div>
      </div>

           
          </div>
        </div>
      </section>

      <section>
      <div className="container purpose-wrapper py-5">
      <div className="row">
      <div className="col-12">
      <div className="wrapper">
      <div className="text text-center">
      <h2><span className="text-gredient">Our  Purpose</span> </h2>
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, voluptate rerum tempore molestiae, ipsam, repellat quibusdam architecto ea laboriosam autem ipsum cupiditate fuga quod. Impedit maiores consectetur blanditiis modi earum ab iure exercitationem voluptatem, numquam incidunt ea laudantium. Possimus rem odit iure aspernatur nostrum dolorem nesciunt sunt laudantium minima eligendi.</p>
      
      </div>
      
      </div>
      
      </div>
      </div>
      
      </div>
      
      </section>


      <Price />
      <section className="bg2">
      <div className="container py-5 why-wrapper">
        <div className="heading text-center text-white-shadow">
          <h2> What our clients says !</h2>
        </div>
        <Testimonials />
      </div>
    </section>

    <section className="faq-wrapper bg-black">
      <div className="container py-5">
        <div className="heading text-center text-white-shadow">
          <h2> Frequently asked questions</h2>
        </div>
        <div className="row">
          <div className="col-12">
          <div className="wrapper">
          
          <FAQ FaqData={faq}/>
          
          </div>
          
          </div>
        </div>
      </div>
    </section>

    </div>
  );
};

export default Service;
