import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageBanner from "../components/PageBanner";
import "./other.css";
import { FaRegStar } from "react-icons/fa6";
import Brands from "../components/Brands";

const About = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const banner = {
    image: "/images/about-banner.png",
    pageName: "About Us",
    heading: "Everything you need to know about ",
    heading2: "Code Sculpt",
    para: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, doloremque at provident cumque deleniti ipsum dolorum porro vel ab enim!",
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="Welcome " />
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
      <PageBanner {...banner} />
      <section className="bg-black">
        <div className="container py-5 ">
          <div className="row">
            <div className="wrapper">
              <div className="text pb-5">
                <h2 className="section-heading text-white-shadow">
                  Our Origin <span className="text-gredient">Story</span>
                </h2>
                <p className="text-white-shadow">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                  officia excepturi perspiciatis? Ratione perspiciatis
                  perferendis aliquid aperiam provident reiciendis temporibus
                  sapiente minus expedita vitae autem possimus amet, recusandae,
                  suscipit, architecto nobis earum. Assumenda quidem officia
                  odio, nesciunt molestias provident labore accusantium. Magnam
                  exercitationem minima fugiat esse architecto eius quos nam.
                </p>
              </div>

              <div className="img img-round-35">
                <img src="images/story.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5 ">
          <div className="row">
            <div className="wrapper">
              <div className="img img-round-35">
                <img src="images/story.jpg" alt="" />
              </div>
              <div className="text pt-4">
                <h2 className="section-heading">
                  Our Origin <span className="text-gredient">Story</span>{" "}
                </h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla
                  officia excepturi perspiciatis? Ratione perspiciatis
                  perferendis aliquid aperiam provident reiciendis temporibus
                  sapiente minus expedita vitae autem possimus amet, recusandae,
                  suscipit, architecto nobis earum. Assumenda quidem officia
                  odio, nesciunt molestias provident labore accusantium. Magnam
                  exercitationem minima fugiat esse architecto eius quos nam.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black values-wrapper">
      <div className="container py-5 ">
      <div className="heading text-center text-white-shadow">
            <h2> Our Values</h2>
          </div>
        <div className="row">
        <div className="col-md-6 mt-4">
        <div className="wrapper">
        <div className="d-flex align-items-end justify-content-between">
        <div className="con-left">
        <span className="h2 mb-3 text-white-shadow d-block"><FaRegStar /></span>
        <h3 className="text-gredient h2">
        Quality
        
        </h3>
        </div>
        <div className="con-write">
        <h3>01</h3>
        </div>
        </div>
        <div className="para pt-4">
        <p className="text-white-shadow">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus sequi dignissimos aperiam amet totam nobis pariatur harum, commodi vero, voluptatem dolor. Veniam blanditiis, obcaecati quisquam distinctio, provident laborum id nostrum placeat porro nemo, deserunt fuga itaque facere sapiente excepturi repudiandae minus similique eligendi possimus ratione? Sunt consequatur cumque doloribus earum.</p>
        
        </div>
        </div>
        
        </div>
        <div className="col-md-6 mt-4">
        <div className="wrapper">
        <div className="d-flex align-items-end justify-content-between">
        <div className="con-left">
        <span className="h2 mb-3 text-white-shadow d-block"><FaRegStar /></span>
        <h3 className="text-gredient h2">
        Reliability
        
        </h3>
        </div>
        <div className="con-write">
        <h3>02</h3>
        </div>
        </div>
        <div className="para pt-4">
        <p className="text-white-shadow">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus sequi dignissimos aperiam amet totam nobis pariatur harum, commodi vero, voluptatem dolor. Veniam blanditiis, obcaecati quisquam distinctio, provident laborum id nostrum placeat porro nemo, deserunt fuga itaque facere sapiente excepturi repudiandae minus similique eligendi possimus ratione? Sunt consequatur cumque doloribus earum.</p>
        
        </div>
        </div>
        
        </div>
        <div className="col-md-6 mt-4">
        <div className="wrapper">
        <div className="d-flex align-items-end justify-content-between">
        <div className="con-left">
        <span className="h2 mb-3 text-white-shadow d-block"><FaRegStar /></span>
        <h3 className="text-gredient h2">
        Creativity
        
        </h3>
        </div>
        <div className="con-write">
        <h3>03</h3>
        </div>
        </div>
        <div className="para pt-4">
        <p className="text-white-shadow">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus sequi dignissimos aperiam amet totam nobis pariatur harum, commodi vero, voluptatem dolor. Veniam blanditiis, obcaecati quisquam distinctio, provident laborum id nostrum placeat porro nemo, deserunt fuga itaque facere sapiente excepturi repudiandae minus similique eligendi possimus ratione? Sunt consequatur cumque doloribus earum.</p>
        
        </div>
        </div>
        
        </div>
        <div className="col-md-6 mt-4">
        <div className="wrapper">
        <div className="d-flex align-items-end justify-content-between">
        <div className="con-left">
        <span className="h2 mb-3 text-white-shadow d-block"><FaRegStar /></span>
        <h3 className="text-gredient h2">
        Ambition
        
        </h3>
        </div>
        <div className="con-write">
        <h3>04</h3>
        </div>
        </div>
        <div className="para pt-4">
        <p className="text-white-shadow">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus sequi dignissimos aperiam amet totam nobis pariatur harum, commodi vero, voluptatem dolor. Veniam blanditiis, obcaecati quisquam distinctio, provident laborum id nostrum placeat porro nemo, deserunt fuga itaque facere sapiente excepturi repudiandae minus similique eligendi possimus ratione? Sunt consequatur cumque doloribus earum.</p>
        
        </div>
        </div>
        
        </div>
        </div>
      </div>
    </section>

<Brands />
<section className="bg-black about-team-wrapper">
        <div className="container py-5 ">
          <div className="heading text-center text-white-shadow">
            <h2>Our Team Members</h2>
          </div>
          <div className="row">
            
            <div className="col-md-6 mt-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/team1.jpg" alt="" />
                </div>
                <div className="text-wrapper">
                  <span>Co Founder / Director</span>
                  <h4 className="text-gredient py-4">Vikas Yadav</h4>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Repudiandae quia natus, mollitia exercitationem recusandae
                    unde consequuntur temporibus obcaecati accusantium ipsum. Ab
                    aliquam quo, expedita quibusdam accusantium a velit
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/team2.jpg" alt="" />
                </div>
                <div className="text-wrapper">
                <button>
                
                </button>
                  <span>Co Founder / Director</span>
                  <h4 className="text-gredient py-4">Ranvijay Yadav</h4>
                  <p>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Repudiandae quia natus, mollitia exercitationem recusandae
                    unde consequuntur temporibus obcaecati accusantium ipsum. Ab
                    aliquam quo, expedita quibusdam accusantium a velit
                  </p>
                </div>
              </div>
            </div>

           
          </div>
        </div>
      </section>

    </div>
  );
};

export default About;
