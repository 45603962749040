import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageBanner from "../components/PageBanner";


const Works = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const banner = {
    image: "/images/work-banner.png",
    pageName: "Our Works",
    heading: "Reach Out for  ",
    heading2: "Excellence",
    para: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, doloremque at provident cumque deleniti ipsum dolorum porro vel ab enim!",
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>Our Works</title>
        <meta name="description" content="Welcome " />
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
      <PageBanner {...banner} />

     


    </div>
  );
};



export default Works