import React from "react";
import { IoStar, IoStarHalf } from "react-icons/io5";
import Slider from "react-slick";
const Testimonials = () => {
  const settings = {
    infinite: true,
    dots: true,
    arrows: false,
    slidesToShow: 3,
    speed: 1000,
    // autoplay: true,
    // autoplaySpeed: 3000,
    // pauseOnHover: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
         
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
         
        }
      },
      
    ]
  };

  const ProductData = {
    data: [
      {
        img: "/images/user.png",
        name: "Digvijay Yadav",
        branch: "Poll Sphere",
        para: " I hired Code Sculpt to create a website for my startup. I had some unique requirements and provided them with a bullet list of features and a design example. Code Sculpt was very helpful in overcoming hurdles and providing solutions. They guided me through the process, educated me, and even improved upon the design I had envisioned. The end result was a website that exceeded my expectations and was delivered within my budget. I highly recommend Code Sculpt to anyone looking for a great experience. Don't hesitate to reach out to them!",
      },
      {
        img: "/images/user.png",
        name: "Vikas Yadav",
        branch: "Continuum Insights",
        para: " To the team at Code Sculpt, I just wanted to take a moment to express my gratitude for the amazing work you did. Our website developer disappeared and our website had been down for several months. Several others tried to restore it, but they could not do it. However, you were able to find an archived copy of the deleted site in just 3 hours and by the end of the day, part of the website was restored! The next morning, the site was fully restored! What you accomplished was truly impressive and I cannot thank you enough. I want everyone to know how wonderful it was to work with you! I look forward to the future and redesigning an even better site with your help! I am so lucky to have found you!",
      },
      {
        img: "/images/user.png",
        name: "Dharm Raj",
        branch: "STEAM Varsity",
        para: " Code Sculpt truly delivered from start to finish! As a small business owner, I needed a professional, modern and beautiful website, and they exceeded my expectations. Collaborating with them was easy and organized, and their pricing was affordable even for my limited budget. Without hesitation, I highly recommend them as your go-to web design team.",
      },
      
    
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        {ProductData.data.map((item, index) => (
          <div className="testimonial-wrapper" key={index}>
          <div className="item-wrapper">
          <div className="wrapper p-3">
              <div className="person d-flex align-items-center justify-content-start">
                <div className="img">
                  <img src={item.img} alt="" />
                </div>
               
                <div className="person-detail ms-3">
                  <span className="d-block">{item.name}</span>
                  <span>{item.branch}</span>
                </div>
              </div>

              <div className="message">
                <div className="rating">
                  <IoStar />
                  <IoStar />
                  <IoStar />
                  <IoStar />
                  <IoStarHalf />
                </div>
                <div className="para mt-3">
                  <p>{item.para}</p>
                </div>
              </div>
            </div>
          </div>
            
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
