import React, { useState } from "react";
import { NavLink } from "react-router-dom";


const Header = () => {
  const [nav, setNav] = useState();
  const navToggle = () => {
    setNav(!nav);
  };

  const allClose = () => {
    setNav(false);
  };

  return (
    <header>
    <div className="header-wrapper">
    <nav className="d-flex align-items-center justify-content-between px-5">
    <div className="nav-left">
    <div className="logo">
    <h1 className="text-gredient"><span>Code</span><span>Sculpt</span></h1>
    </div>
    </div>
    <div className="nav-rite">
    <ul>
    <li><NavLink to="/">Home</NavLink></li>
    <li><NavLink to="/about-us">About Us</NavLink></li>
    <li><NavLink to="/service">Our Services</NavLink></li>
    <li><NavLink to="/our-work">Our Work</NavLink></li>
    <li><NavLink to="/contact-us">Contact Us</NavLink></li>
    </ul>
    
    </div>
    
    </nav>
    
    
    </div>
    
    
    
    </header>
  );
};

export default Header;
