import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageBanner from "../components/PageBanner";
import "./other.css";
import {

  FaLocationDot,
  FaPhone,
  FaRegEnvelope,

} from "react-icons/fa6";

const Contact = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const banner = {
    image: "/images/contact-banner.png",
    pageName: "Contact Us",
    heading: "Reach Out for  ",
    heading2: "Excellence",
    para: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, doloremque at provident cumque deleniti ipsum dolorum porro vel ab enim!",
  };

  return (
    <div className="page-content">
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Welcome " />
        <meta name="robots" content="noindex, nofollow"></meta>
      </Helmet>
      <PageBanner {...banner} />

      <section className="bg-black contact-form-wrapper">
        <div className="container py-5">
        <div className="heading">
        <h2>Submit Your Detail Here</h2>
        
        </div>
          <div className="row">
            <div className="col-md-6">
              <div className="wrapper">
                <div class="form">
                  <form action="form/form.php" method="POST">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-input-group">
                          <input
                            id="lastName"
                            type="text"
                            name="fullName"
                            required
                          />
                          <label for=""> Full Name</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-input-group ">
                          <input
                            id="firstName"
                            type="number"
                            name="mobilNumber"
                            required
                          />
                          <label for=""> Mobil Number</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-input-group ">
                          <input
                            id="email"
                            type="email"
                            name="email"
                            required
                          />
                          <label for=""> Email</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-input-group">
                          <input
                            id="company"
                            type="text"
                            name="company"
                            required
                          />
                          <label for=""> Company Name</label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-input-textarea">
                          <textarea
                            class="w-100"
                            name="message"
                            id=""
                            rows="5"
                            required
                          ></textarea>
                          <label for=""> Message</label>
                        </div>
                      </div>
                    </div>
                    <button className="button" role="button">
                      <span class="text">Submit</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="wrapper h-100">
            <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56143.74609155409!2d76.96647722167971!3d28.419735700000018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23e13dd4752b%3A0x2474d237a0839d2a!2sContinuum%20Insights!5e0!3m2!1sen!2sin!4v1705679129631!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
            </div>
            
            </div>
          </div>
        </div>
      </section>

<section className="contact-detail-wrapper">
<div className="container py-5">
<div className="row">
<div className="col-md-4">
<div className="wrapper">

<div className="detail">
<span className="icon"><FaLocationDot /></span>
<p>5th Floor, 501, JMD Megapolis IT Park, Sohna Road, Sector 48, Gurugram, Gurugram, Haryana, 122018 </p>

</div>




</div>


</div>
<div className="col-md-4">
<div className="wrapper"><NavLink>
<div className="detail">
<span className="icon"><FaPhone /></span>
<p>1234567890 </p>

</div>
</NavLink></div>

</div>
<div className="col-md-4">
<div className="wrapper"><NavLink>
<div className="detail">
<span className="icon"><FaRegEnvelope /></span>
<p> codesculpt@gmail.com</p>

</div>
</NavLink></div>

</div>

</div>


</div>

</section>


    </div>
  );
};

export default Contact;
