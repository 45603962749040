import React from "react";
import { NavLink } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const Brands = () => {
  const Brand = {
    data: [
      { img1: "/images/brands-logo/logoipsum1.png" },
      { img1: "/images/brands-logo/logoipsum2.png" },
      { img1: "/images/brands-logo/logoipsum3.png" },
      { img1: "/images/brands-logo/logoipsum4.png" },
      { img1: "/images/brands-logo/logoipsum5.png" },
      { img1: "/images/brands-logo/logoipsum6.png" },
      { img1: "/images/brands-logo/logoipsum7.png" },
      { img1: "/images/brands-logo/logoipsum8.png" },
  
      
    ],
  };
  return (
    <section className="py-5">
      <div className="container brands-wrapper mt-5">
        <div className="heading text-center pb-4">
          <h2>Brands Trust Us</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis saepe reprehenderit expedita quaerat vitae culpa?
          </p>
        </div>
        <div className="row">
          {Brand.data.map((item, index) => (
            <div className="col-lg-3 col-md-4 cl-sm-6 mt-4" key={index}>
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src={item.img1} alt="brands" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Brands;
