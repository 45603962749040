import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./home.css";
import WhySlider from "../components/WhySlider";
import Testimonials from "../components/Testimonials";
import FAQ from "../components/FAQ";
import Price from "../components/Price";
const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

const faq ={
  data:[
    {
      id:1,
      heading:"What is website development?",
      para:"Website development is the process of building and maintaining a website, including everything from designing the layout to writing the code that makes it function. It's an essential part of establishing your online presence and connecting with customers or followers. Whether you're a small business owner, a blogger, or an aspiring entrepreneur, website development is a crucial skill to have in today's digital age."
    },
    {
      id:2,
      heading:"Why is a professional website important for my business?",
      para:"Having a professional website is crucial for any business. A well-designed website not only attracts potential customers but also builds trust with them. It is an effective way to showcase your products or services and establish your brand's credibility in the market. Without a professional website, you risk losing out on valuable opportunities to grow your business and increase your revenue. So, connect with  Code Sculpt and take your business to new heights!"
    },
    {
      id:3,
      heading:"How much does website development cost?",
      para:"When it comes to website development, the cost can vary depending on numerous factors. The size and complexity of the site, the desired features, and the level of customization all play a role in determining the final cost. To ensure you receive an accurate estimate based on your unique needs, it's essential to obtain a detailed quote from a professional development company. Don't settle for a one-size-fits-all solution. Get a tailored estimate today and take the first step towards creating the website of your dreams!"
    },
    {
      id:4,
      heading:"Does Code Sculpt provide ongoing support and maintenance?",
      para:"Yes, If you are looking for a reliable and trustworthy partner that can provide you with ongoing support and maintenance. Look no further than Code Sculpt. Our team of experts is dedicated to helping you achieve your goals by providing continuous support and maintenance services. Choose Code Sculpt, and let us be your partner in success."
    },
 


]
}

  return (
    <div className="page-content">
      <Helmet>
        <title>Code Sculpt</title>
        <meta name="description" content="Welcome " />
      </Helmet>
      <section>
        <div className="container home-banner py-4">
          <div className="row d-flex ">
            <div className="col-md-5">
              <div className="wrapper">
                <div className="text d-flex align-items-center">
                  <div className="">
                    <h1>
                      Welcome to{" "}
                      <span className="text-gredient">Code Sculpt</span>
                    </h1>
                   
                    <p>
                    Code Sculpt has expertise in designing, redesigning, and continuously supporting enterprise and customer-facing web apps, resulting in high conversion and adoption rates, with over 350 successful web projects.
                    </p>
                    <NavLink className="button" to="/contact-us"><span>Contact Us</span> </NavLink>
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6 px-lg-5">
              <div className="wrapper">
                <div className="img-wrapper">
                  <div className="img">
                    <img src="images/home-banner.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-black home-service-wrapper">
        <div className="container py-5 ">
          <div className="heading text-center text-white-shadow">
            <h2>Our Main Services</h2>
          </div>
          <div className="row">
            <div className="col-md-6 mt-4">
              <div className="wrapper">
                <div className="text-wrapper">
                  <span className="count">01</span>
                  <h3>Website Designing</h3>
                  <p>
                  Looking for top-notch web design services to take your website's UI/UX to the next level? We've got you covered! Don't settle for anything less than the best - hire us today and watch your website transform into a stunning masterpiece!
                  </p>
                </div>
                <div className="img-wrapper">
                  <img
                    className="rounded"
                    src="/images/web-devlopment.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="wrapper">
                <div className="img-wrapper">
                  <img src="/images/web-dev.jpg" alt="" />
                </div>
                <div className="text-wrapper">
                  <span className="count">02</span>
                  <h3>Website Development</h3>
                  <p>
                  As a leading web design and development company, we specialize in creating exceptional websites that offer an intuitive interface, seamless navigation, and an unparalleled user experience.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 pt-4">
              <div className="wrapper ">
                <div className="row ">
                  <div className="col-md-6">
                    <div className="text-wrapper">
                      <span className="count">03</span>
                      <h3>Mobile App Development</h3>
                      <p>
                      Code Sculpt is a reliable mobile application development that helps startups and small businesses. Our top-notch developers and designers have the expertise to navigate the challenges of the global market.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="img-wrapper">
                      <img src="/images/app-dev.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 pt-4">
              <div className="wrapper ">
                <div className="row ">
                <div className="col-md-6">
                    <div className="img-wrapper">
                      <img src="/images/service2.jpg" alt="" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="text-wrapper">
                      <span className="count">03</span>
                      <h3>Digital Marketing Services</h3>
                      <p>
                      Are you seeking professional support to boost your online presence and increase your brand visibility? You've come to the right place! Our team specializes in a wide range of digital marketing services including search engine optimization (SEO), social media marketing, pay-per-click (PPC) advertising, email marketing, and more. 
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-5 why-wrapper">
          <div className="heading text-center text-white-shadow">
            <h2> Why work with us?</h2>
          </div>
          <WhySlider />
        </div>
      </section>
      <Price />

      <section className="bg2">
        <div className="container py-5 why-wrapper">
          <div className="heading text-center text-white-shadow">
            <h2> What our clients says !</h2>
          </div>
          <Testimonials />
        </div>
      </section>

      <section className="faq-wrapper bg-black">
        <div className="container py-5">
          <div className="heading text-center text-white-shadow">
            <h2> Frequently asked questions</h2>
          </div>
          <div className="row">
            <div className="col-12">
            <div className="wrapper">
            
            <FAQ FaqData={faq}/>
            
            </div>
            
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
