import React from "react";
import { Collapse } from "antd";
const { Panel } = Collapse;
const FAQ = ({ FaqData }) => {
  const onChange = (key) => {
    console.log(key);
  };
  console.log(FaqData);
  return (
    <div>
      <Collapse bordered={false} onChange={onChange}>
        {FaqData.data.map((item) => (
          <Panel className="font16" header={item.heading} key={item.id}>
            <p>{item.para}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default FAQ;
